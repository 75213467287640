import React from "react";

function Footer() {
    return (
        <footer>
            <p>&copy; 2024 Josephine Kvarnberg</p>
        </footer>
    )
};

export default Footer;