import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";

function scrollToTop() {
    scroll.scrollToTop();
  };

function Navbar () {
    return (
        <header>
            <nav>
            <h1 onClick={scrollToTop}>
            Josephine Kvarnberg
            </h1>
            
                <ul>
                    <li>
                        <Link
                            activeClass="active"
                            to="section1"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                        About me
                        </Link>
                    </li>
                    <li><Link
                            activeClass="active"
                            to="section2"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                        Background
                        </Link>
                    </li>
                    <li><Link
                            activeClass="active"
                            to="section3"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                        Projects
                        </Link>
                    </li>
                    <li><Link
                            activeClass="active"
                            to="section4"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                        Contact
                        </Link>
                    </li>
                </ul>
                </nav>
        </header>
    )
        /*<nav className="nav">
            <div className="nav-content">
                <h1
                onClick={scrollToTop}
                >Josephine Kvarnberg</h1>
                <ul className="nav-items">
                    <li className="nav-item">
                        <Link
                            activeClass="active"
                            to="section1"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                        About me
                        </Link>
                    </li>
                    <li className="nav-item"><Link
                            activeClass="active"
                            to="section2"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                        My projects
                        </Link>
                    </li>
                    <li className="nav-item"><Link
                            activeClass="active"
                            to="section3"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                        Contact
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>)*/
};

export default Navbar;