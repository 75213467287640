import './App.css';
import React from 'react';
import Navbar from './Components/Navigation';
import Section from './Components/Section';
import About from './AboutText';
import Work from './WorkText';
import Projects from './ProjectsSide'
import profile from './Assets/CV_MBD-74.jpg'

import Footer from './Components/Footer';

function App() {
  return (
    <div className='App'>
      <Navbar/>
      <Section 
        title="About me"
        img={profile}
        subtitle={About}
        id="section1"
      />
      <Section
        title="Background"
        subtitle={Work}
        id="section2"
      ></Section>
      <Section
        title="Projects"
        id="section3"
        comp={<Projects/>}>
      </Section>
      <Section
        title="Contact"
        subtitle={<section><p><a
          href="mailto:josephinekvarnberg@gmail.com?Subject=Hello!"
          target="_blank"
          rel="noopener noreferrer"
        >
          josephinekvarnberg@gmail.com
        </a></p>
        <p>
          <a href="https://www.linkedin.com/in/josephine-kvarnberg-311602174" target="_blank" rel="noopener noreferrer">
          LinkedIn
          </a>
        </p>
        </section>}
        id="section4"
        />
      <Footer/>
    </div>);
}

export default App;
