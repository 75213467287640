/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import phong from "./Assets/phong.webp";
import jokeapp from "./Assets/jokeapp.jpg";
import ARvuforia from "./Assets/ARvuforia.png";
import somadesign from "./Assets/somadesign.png";
import thesis from "./Assets/thesis.png";

/* This function should maybe use another component called Project? For each project */
function Projects() {
    return (
        <section class="projects">
            <a href="http://kth.diva-portal.org/smash/record.jsf?pid=diva2%3A1748288&dswid=4768" target="_blank" rel="noreferrer" class="proj-link"> 
                <article class="project">
                    <img src={thesis} alt="thesis front page" class="project-image"/>
                    <div class="project-desc">
                        <h3>Master's Thesis</h3>
                        <p>Thesis title: User experience of the login flow in Duo (Användarupplevelse av inloggningsflödet i Duo). Done at the Duo team at SVTi.</p>
                    </div>
                </article>
            </a>
            <a href="https://lillekvarre.wixsite.com/website" target="_blank" rel="noreferrer" class="proj-link">
            <article class="project">
                <img src={phong} alt="phong reflection model 3d scene" class="project-image" />
                <div class="project-desc">
                    <h3>Project in the course DH2323 Computer Graphics and Interaction.</h3>
                    <p>Getting to know the Phong reflection model: A simple implementation with a ray tracer in C++, with SDL2 and GLM.</p>
                </div>
            </article>
            </a>
            <a href="https://vimeo.com/558742588" target="_blank" rel="norefferer" class="proj-link">
                <article class="project">
                    <img src={somadesign} alt="part from video" class="project-image"/>
                    <div>
                        <h3>The Soma Journey. Project in the course DH2629 Interaction Design as a Reflective Practice.</h3>
                        <p>Design exploration with design brief provided by ManyOne (Boris Design), with aim to explore CIBES lifts as a design space and develop an idea of a concept regarding various affordances of time, space, feedback and safety that are present in the elevator. Project was presented with a pictorial and video. Done together with Julia Huang and Priyanka Syal.</p>
                    </div>

                </article>
            </a>
            <a href="https://vimeo.com/499250505" target="_blank" rel="norefferer" class="proj-link">
                <article class="project">
                    <img src={ARvuforia} alt="part from video" class="project-image"/>
                    <div>
                        <h3>The AR Petting Zoo. Project in DT2140 Multimodal Interaction and Interfaces.</h3>
                        <p>An exploration of working with 3D models in Unity, using Vuforia. Done together with Sara Nazeriha, Jiangyue Han and Yihang Zhao.</p>
                    </div>
                </article>
            </a>
            <a href="https://project-joke-6bbc1.web.app/" rel="norefferer" target="_blank" class="proj-link">
                <article class="project">
                    <img src={jokeapp} alt="still from website" class="project-image"/>
                    <div>
                        <h3>The joke app. Project in DH2642 Interaction Programming and the Dynamic Web.</h3>
                        <p>
                        A web application created using Javascript with React, combining a joke API with Firebase storing and authentication. The development helped combined React JS and working with an open API, as well as storage and user authentication. 
                        Done together with Jasmine Mohamed, Julia Huang and Sara Nazeriha.
                        </p>
                    </div>
                </article>
            </a>
        </section>
    )
}

export default Projects;